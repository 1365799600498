import React from 'react';

const Fall = () => {
  return (
    <div className="tile is-ancestor">
      {/* dovetails */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">dovetails</h3>
          <hr className="my-2" />
        </div>
        <div className="flex">
          <article className="tile is-child">
            <img src="/img/wtc/handtools/dovetail/dovetail_first.jpeg" alt="" />
          </article>
          <article className="tile is-child">
            <img src="/img/wtc/handtools/dovetail/dovetail2.jpeg" alt="" />
          </article>
          <article className="tile is-child">
            <img src="/img/wtc/handtools/dovetail/dovetail.jpeg" alt="" />
          </article>
        </div>
        <div className="flex items-center">
          <article className="tile is-child my-2 mr-2">
            <img src="/img/wtc/handtools/dovetail/dovetail3.jpeg" alt="" />
          </article>
          <article className="tile is-child my-2">
            <img src="/img/wtc/handtools/dovetail/dovetail_both.jpeg" alt="" />
          </article>
        </div>
      </div>
      {/* frame project */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">frame project</h3>
          <hr className="my-2" />
        </div>
        <div className="tile">
          <img src="/img/wtc/handtools/frame_proj.jpeg" alt="frame project" />
        </div>
      </div>
      {/* mortar & tenon */}
      <div className="tile m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">mortar and tenons</h3>
          <hr className="my-2" />
        </div>
        <div className="flex tile">
          <div className="tile is-parent" style={{ flexGrow: '2' }}>
            <article className="py-4 tile is-child">
              <img
                src="/img/wtc/handtools/mortar_n_tenon/mandt.jpeg"
                alt="mortar and tenon"
              />
            </article>
          </div>
          <div className="tile flex flex-col is-vertical pl-4">
            <article className="py-4 tile is-child">
              <img
                src="/img/wtc/handtools/mortar_n_tenon/mandt3.jpeg"
                alt="mortar and tenon"
              />
            </article>

            <article className="tile is-child">
              <img
                src="/img/wtc/handtools/mortar_n_tenon/mandt_peel.jpeg"
                alt="mortar and tenon"
              />
            </article>

            <article className="py-4 tile is-child">
              <img
                src="/img/wtc/handtools/mortar_n_tenon/mandt2.jpeg"
                alt="mortar and tenon"
              />
            </article>
          </div>
        </div>
      </div>
      {/* push stick */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">push stick</h3>
          <hr className="my-2" />
        </div>
        <div className="flex items-center">
          <article className="tile is-child my-2 mr-2">
            <img
              src="/img/wtc/push_stick/push_stick.jpeg"
              alt="saw push stick"
            />
          </article>
          <article className="tile is-child my-2">
            <img
              src="/img/wtc/push_stick/push_stick2.jpeg"
              alt="saw push stick"
            />
          </article>
        </div>
      </div>

      {/* sawhorse */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">sawhorse</h3>
          <hr className="my-2" />
        </div>
        <div className="tile flex justify-center drop-shadow">
          <img src="/img/wtc/sawhorse/draw.png" alt="sawhorse plan" />
        </div>
        <div className="flex items-center">
          <article className="tile is-child my-2 mr-2">
            <img src="/img/wtc/sawhorse/sawhorse.jpeg" alt="sawhorse" />
          </article>
          <article className="tile is-child my-2">
            <img src="/img/wtc/sawhorse/sawhorse2.jpeg" alt="sawhorse" />
          </article>
        </div>
      </div>
      {/* stubwall */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">stubwall</h3>
          <hr className="my-2" />
        </div>

        <div className="flex">
          <article className="py-4 tile is-child">
            <img src="/img/wtc/stubwall/plan.jpeg" alt="" />
          </article>
        </div>
        <div className="flex items-center">
          <article className="py-4 tile is-child">
            <img src="/img/wtc/stubwall/first.jpeg" alt="" />
          </article>
          <article className="py-4 pl-4 tile is-child">
            <img src="/img/wtc/stubwall/second.jpeg" alt="" />
          </article>
        </div>
        <div className="flex">
          <article className="tile is-child">
            <img src="/img/wtc/stubwall/final.jpeg" alt="" />
          </article>
        </div>
      </div>

      {/* toolbox */}
      <div className="m-6">
        <div className="my-4">
          <h3 className="font-headline my-2">toolbox</h3>
          <hr className="my-2" />
        </div>

        <div className="flex items-center">
          <div className="tile is-parent">
            <article className="py-4 tile is-child">
              <img src="/img/wtc/toolbox/toolbox_plan.jpeg" alt="" />
            </article>
          </div>
          <div className="tile flex flex-col is-vertical pl-4">
            <article className="py-4 tile is-child">
              <img src="/img/wtc/toolbox/toolbox_glue.jpeg" alt="" />
            </article>

            <article className="tile is-child">
              <img src="/img/wtc/toolbox/toolbox_ends.jpeg" alt="" />
            </article>
          </div>
        </div>

        <div className="py-4">
          <article className="tile is-child">
            <img src="/img/wtc/toolbox/toolbox_endview.jpeg" alt="" />
          </article>
        </div>

        <div className="flex">
          <article className="tile is-child">
            <img src="/img/wtc/toolbox/toolbox_sideview.jpeg" alt="" />
          </article>
        </div>

        <div className="flex tile">
          <div className="tile flex flex-col is-vertical pr-4">
            <article className="py-4 tile is-child">
              <img src="/img/wtc/toolbox/toolbox_handle2.jpeg" alt="" />
            </article>

            <article className="tile is-child">
              <img src="/img/wtc/toolbox/toolbox_edge.jpeg" alt="" />
            </article>

            <article className="py-4 tile is-child">
              <img src="/img/wtc/toolbox/toolbox_handle.jpeg" alt="" />
            </article>
          </div>
          <div className="tile is-parent" style={{ flexGrow: '2' }}>
            <article className="py-4 tile is-child">
              <img src="/img/wtc/toolbox/toolbox.jpeg" alt="" />
            </article>
          </div>
        </div>
      </div>
      {/* mallet */}

      {/* drillpress fixture */}
    </div>
  );
};

export default Fall;
